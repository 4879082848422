import { useAsyncData, useNuxtApp } from '#imports'

import type {
  GetPaymentMethods,
  PaymentMethod,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { usePaymentMethods } from '../../../api/getPaymentMethods/usePaymentMethods.api'
import { filterABTestedPaymentMethods } from '../helpers/filterABTestedPaymentMethods'

/**
 * Returns the cached Payment Methods for the current country
 *
 * @example
 * const { data: paymentMethods } = await useMarketPaymentMethods()
 */
export function useMarketPaymentMethods(options?: { immediate: boolean }) {
  const experiments = useExperiments()
  const { market } = useMarketplace()

  const getPaymentMethods = usePaymentMethods()

  return useAsyncData<GetPaymentMethods.Response, unknown, PaymentMethod[]>(
    'use-market-payment-methods',
    () =>
      getPaymentMethods({ countryCode: market.countryCode, tenant: 'SALES' }),
    {
      dedupe: 'defer',
      transform: (data) =>
        filterABTestedPaymentMethods(experiments, data.results),
      ...options,

      // Avoid re-fetching the data multiple on the client side
      getCachedData: (key) => useNuxtApp().payload.data[key] || undefined,
    },
  )
}
