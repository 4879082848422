import {
  type MaybeRefOrGetter,
  type Ref,
  computed,
  toRef,
  toValue,
  watch,
} from 'vue'

import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import { useMarketPaymentMethods } from './useMarketPaymentMethods'

/**
 * A convenience composable to deal with payment methods (typically as a prop).
 * If `paymentMethods` is passed and not empty, its value will be used.
 * Otherwise, it falls back to the market's payment methods.
 *
 * @example
 * const props = defineProps<{
 *   paymentMethods: PaymentMethod[]
 * }>()
 *
 * const paymentMethods = usePaymentMethodsWithFallback(() => props.paymentMethods)
 *
 * @param paymentMethods
 * @returns A ref object containing payment methods
 */
export function usePaymentMethodsWithFallback(
  paymentMethods: MaybeRefOrGetter<
    PaymentMethod[] | PaymentMethod['bmCode'][] | null | undefined
  >,
): Ref<PaymentMethod[]> {
  const { data: marketPaymentMethods, execute } = useMarketPaymentMethods({
    immediate: !toValue(paymentMethods),
  })

  // Make sure to retrieve the market payment methods if the prop becomes null.
  watch(toRef(paymentMethods), (methods) => {
    if (!methods && !marketPaymentMethods.value) {
      void execute()
    }
  })

  return computed(() => {
    const paymentMethodsValue = toValue(paymentMethods) ?? []
    const marketPaymentMethodsValue = toValue(marketPaymentMethods) ?? []

    if (paymentMethodsValue.length === 0) {
      return marketPaymentMethodsValue
    }

    return typeof paymentMethodsValue[0] === 'string'
      ? marketPaymentMethodsValue.filter((method) =>
          (paymentMethodsValue as PaymentMethod['bmCode'][]).includes(
            method.bmCode,
          ),
        )
      : (paymentMethodsValue as PaymentMethod[])
  })
}
