import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'
import { camelizeKeys } from '@backmarket/utils/object/camelizeKeys'

import type {
  GetPaymentMethodsOptions,
  GetPaymentMethodsOptionsVNext,
  LegacyGetPaymentMethodsOptions,
} from './getPaymentMethods.specs'
import { mapGetPaymentMethodsResponseToLegacyResponse } from './getPaymentMethods.utils'

export function usePaymentMethods() {
  const $httpFetch = useHttpFetch()

  async function legacyGetPaymentMethods({
    countryCode,
    listings,
    bagPrice,
    insuranceSubscription,
    features,
  }: LegacyGetPaymentMethodsOptions) {
    const data = await $httpFetch('/payment/payment_methods', {
      query: {
        country_code: countryCode,
        listings: listings?.join(','),
        bag_price: bagPrice?.amount,
        insurance_subscription: insuranceSubscription,
        features: features?.join(','),
      },
    })

    return camelizeKeys(data)
  }

  async function getPaymentMethods({
    tenant,
    countryCode,
    listings,
    bagPrice,
    features,
  }: GetPaymentMethodsOptionsVNext) {
    const data = await $httpFetch('/payment/payment-methods', {
      query: {
        tenant,
        country: countryCode,
        client_platform: 'WEB',
        listings_ids: listings?.join(','),
        price: bagPrice?.amount,
        features: features?.join(','),
      },
    })

    return mapGetPaymentMethodsResponseToLegacyResponse(data, { countryCode })
  }

  return async (options: GetPaymentMethodsOptions) => {
    if (options.tenant === 'TRADEIN') {
      return getPaymentMethods(options)
    }

    return legacyGetPaymentMethods(options)
  }
}
