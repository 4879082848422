import type { Currency, MarketCountryCode } from '@backmarket/http-api'
import type {
  GetPaymentMethods as HttpV1GetPaymentMethods,
  PaymentGroup,
  PaymentMethod,
  PaymentServiceProvider,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { camelizeKeys } from '@backmarket/utils/object/camelizeKeys'

import type {
  GetPaymentMethods,
  PaymentGateway,
  PaymentMethodParameter,
  PaymentMethodVNext,
} from './getPaymentMethods.specs'

/**
 * Map new payment gateway to legacy payment service provider
 */
function mapGatewayToServiceProvider(
  gateway: PaymentGateway,
): PaymentServiceProvider {
  switch (gateway) {
    case 'PROCESSOUT':
      return 'processout'
    case 'ADYEN':
    default:
      return 'adyen'
  }
}

/**
 * Map new configuration parameter name to legacy config field name
 */
function mapPaymentMethodParameterNameToLegacyName(name: string) {
  switch (name) {
    case 'card.brand':
      return 'supportedBrands'
    default:
      return name
  }
}

function mapPaymentMethodParametersToLegacyConfig(
  parameters: PaymentMethodParameter[],
) {
  return parameters.reduce(
    (acc, parameter) => {
      if (parameter.values) {
        // eslint-disable-next-line no-param-reassign
        acc[mapPaymentMethodParameterNameToLegacyName(parameter.name)] =
          parameter.values.map(({ name }) => name)
      }

      return acc
    },
    {} as Record<string, unknown>,
  )
}

function mapPaymentMethodVNextToLegacy(
  method: PaymentMethodVNext,
  extraData: Pick<PaymentMethod, 'countryCode'>,
): PaymentMethod {
  return {
    reference: method.reference,
    bmCode: method.code,
    group: method.frontendConfiguration.group ?? (method.code as PaymentGroup),
    networks: method.frontendConfiguration.icons,
    pspCode: mapGatewayToServiceProvider(method.gateway),
    config: {
      ...camelizeKeys(method.frontendConfiguration.values),
      ...mapPaymentMethodParametersToLegacyConfig(method.parameters),
    },

    // TODO: change this based on the errors we get for this payment method
    enabled: true,
    errors: [],

    // TODO: discuss with BE how to retrieve the currency
    // /!\ Apple pay will not work without currency
    currency: 'unknown' as unknown as Currency,

    // fraud partner will be removed in the future. Replaced with a unique partner for all payment methods.
    // for now, we keep it empty (= no fraud library)
    fraudCheckPartner: '',

    // Deprecated fields no longer used (or barely used)
    promoCodeEnabled: true,
    autoCaptureEnabled: false,
    needMerchantAgreement: false,
    hppEnabled: false,
    brandCode: 'unknown',
    architecture: 'finpay',
    integrationType: 'adyen_checkout_api',

    ...extraData,
  }
}

/**
 * Take the response from the new payment methods endpoint and map it to the old format.
 * /!\
 * - This will not work for Apple Pay methods.
 * - This will never provide a fraud partner.
 * @deprecated https://backmarket.atlassian.net/browse/PAYIN-4854
 */
export function mapGetPaymentMethodsResponseToLegacyResponse(
  response: GetPaymentMethods['response'],
  extraData: {
    countryCode: MarketCountryCode
  },
) {
  return {
    count: response.paymentMethods.length,
    next: null,
    previous: null,
    results: response.paymentMethods.map((method) =>
      mapPaymentMethodVNextToLegacy(method, extraData),
    ),
  } satisfies HttpV1GetPaymentMethods.Response
}
