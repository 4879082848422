import type { UnionOfPropertyTypes } from '@backmarket/utils/object/UnionOfPropertyTypes'

import type { DjangoPaginatedResults } from '../../api-django'
import type { MarketCountryCode, Price } from '../../api-models'
import { Currency } from '../../standards'

/**
 * BackMarket payment flow architecture
 *
 * (OpenAPI specs not available)
 */
export const PaymentArchitecture = {
  LEGACY: 'legacy',
  FINPAY: 'finpay',
} as const

export type PaymentArchitecture = UnionOfPropertyTypes<
  typeof PaymentArchitecture
>

/**
 * Backmarket code of a payment method
 *
 * (OpenAPI specs not available)
 */
export const PaymentMethodCode = {
  AFFIRM: 'affirm',
  AFTERPAY: 'afterpay',
  APPLE_PAY: 'apple_pay',
  CARD: 'card',
  CLEARPAY: 'clearpay',
  EPS: 'eps',
  GIROPAY: 'giropay',
  GOOGLE_PAY: 'google_pay',
  IDEAL: 'ideal',
  KLARNA_PAY_LATER: 'klarna_pay_later',
  KLARNA_SLICE_IT: 'klarna_slice_it',
  MBWAY: 'mbway',
  FAKE: 'fake',
  ONEY10X: 'oney10x',
  ONEY12X: 'oney12x',
  ONEY3X: 'oney3x',
  ONEY4X: 'oney4x',
  ONEY6X: 'oney6x',
  PAYPAL: 'paypal',
  PAYPAY: 'paypay',
  PROCESSOUT_SANDBOX: 'processout_sandbox',
  SCALAPAY: 'scalapay',
  SOFORT: 'sofort',
  TRUSTLY: 'trustly',
  KONBINI_GENERIC: 'konbini_generic',
} as const

export type PaymentMethodCode = UnionOfPropertyTypes<typeof PaymentMethodCode>

/**
 * Errors which avoids the payment method to be available
 *
 * (OpenAPI specs not available)
 */
export const PaymentError = {
  DISABLED: 'not_activated',
  COUNTRY_DISABLED: 'country_not_activated',
  ONLY_MERCHANT_INCOMPATIBLE: 'merchant_not_valid',
  ONE_MERCHANT_INCOMPATIBLE: 'one_merchant_is_not_valid',
  MANY_MERCHANTS_INCOMPATIBLE: 'incompatible_merchants',
  PRICE_INCOMPATIBLE: 'price_not_eligible',
  INSURANCE_INCOMPATIBLE: 'insurance_subscription',
} as const

export type PaymentError = UnionOfPropertyTypes<typeof PaymentError>

/**
 * Fraud system used with a payment method
 *
 * (OpenAPI specs not available)
 */
export const PaymentFraudPartner = {
  SIGNIFYD: 'signifyd',
  RAVELIN: 'ravelin',
} as const

export type PaymentFraudPartner = UnionOfPropertyTypes<
  typeof PaymentFraudPartner
>

/**
 * Some payment method may share a common group, in which case front-end apps
 * are expected to display them as grouped, in the payment form.
 *
 * (OpenAPI specs not available)
 */
export const PaymentGroup = {
  AFFIRM: 'affirm',
  AFTERPAY: 'afterpay',
  APPLE_PAY: 'apple_pay',
  CARD: 'card',
  CLEARPAY: 'clearpay',
  EPS: 'eps',
  GIROPAY: 'giropay',
  GOOGLE_PAY: 'google_pay',
  IDEAL: 'ideal',
  KLARNA_PAY_LATER: 'klarna_pay_later',
  KLARNA_SLICE_IT: 'klarna_slice_it',
  KONBINI: 'konbini',
  MBWAY: 'mbway',
  FAKE: 'fake',
  ONEY: 'oney',
  PAYPAL: 'paypal',
  PAYPAY: 'paypay',
  PROCESSOUT_SANDBOX: 'processout_sandbox',
  SCALAPAY: 'scalapay',
  SOFORT: 'sofort',
  TRUSTLY: 'trustly',
} as const

export type PaymentGroup = UnionOfPropertyTypes<typeof PaymentGroup>

/**
 * Type of integration used for a payment method
 *
 * (OpenAPI specs not available)
 */
export const PaymentIntegrationType = {
  ADYEN_CHECKOUT_API: 'adyen_checkout_api',
  HPP: 'hpp',
  NATIVE: 'native',
} as const

export type PaymentIntegrationType = UnionOfPropertyTypes<
  typeof PaymentIntegrationType
>

/**
 * Identifiers that represents brands to display, when advertising a payment
 * method.
 *
 * (OpenAPI specs not available)
 */
export const PaymentNetwork = {
  ACCEL: 'accel',
  AFFIRM: 'affirm',
  AFTERPAY: 'afterpay',
  AMERICAN_EXPRESS: 'amex',
  APPLE_PAY: 'apple_pay',
  BANCONTACT: 'bancontact',
  CARTES_BANCAIRES: 'cb',
  CLEARPAY: 'clearpay',
  DINERS: 'diners',
  DISCOVER: 'discover',
  EPS: 'eps',
  FAMILYMART: 'familymart',
  GIROPAY: 'giropay',
  GOOGLE_PAY: 'google_pay',
  IDEAL: 'ideal',
  JCB: 'jcb',
  KLARNA: 'klarna',
  LAWSON: 'lawson',
  MAESTRO: 'maestro',
  MASTERCARD: 'mastercard',
  MBWAY: 'mbway',
  FAKE: 'fake',
  MINISTOP: 'ministop',
  NYCE: 'nyce',
  ONEY: 'oney',
  PAYEASY: 'payeasy',
  PAYPAL: 'paypal',
  PAYPAY: 'paypay',
  PROCESSOUT: 'processout',
  PULSE: 'pulse',
  SCALAPAY: 'scalapay',
  SEICOMART: 'seicomart',
  SOFORT: 'sofort',
  TRUSTLY: 'trustly',
  VISA: 'visa',
} as const

export type PaymentNetwork = UnionOfPropertyTypes<typeof PaymentNetwork>

/**
 * Payment provider used through a payment method
 *
 * (OpenAPI specs not available)
 */
export const PaymentServiceProvider = {
  ADYEN_MARKETPAY: 'adyen_marketpay',
  ADYEN_PAYIN_PAYOUT: 'adyen',
  PAYPAL: 'paypal',
  PROCESSOUT: 'processout',
} as const

export type PaymentServiceProvider = UnionOfPropertyTypes<
  typeof PaymentServiceProvider
>

export const AdyenCardBrands = {
  AMEX: 'amex',
  BCMC: 'bcmc',
  CARTEBANCAIRE: 'cartebancaire',
  DISCOVER: 'discover',
  JCB: 'jcb',
  MAESTRO: 'maestro',
  MAESTROUK: 'maestrouk',
  MC: 'mc',
  VISA: 'visa',
  VISAALPHABANKBONUS: 'visaalphabankbonus',
  VISADANKORT: 'visadankort',
} as const
export type AdyenCardBrands = UnionOfPropertyTypes<typeof AdyenCardBrands>

export type AdyenCardPaymentMethodConfig = {
  publicKey: string
  environment: 'live' | 'test'
  supportedBrands?: AdyenCardBrands[]
}

export type AdyenIdealPaymentMethodConfig = {
  issuers: { id: string; name: string }[]
}

export type KlarnaPaymentMethodConfig = {
  clientId: string
  libraryUrl: string
  environment?: string
}

export type SquarePaymentMethodConfig = {
  libraryUrl: string
  // clearpay/afterpay merchant id
  mpid: string
  placementId: string
}

export type ApplePayPaymentMethodConfig = {
  // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/1916123-merchantcapabilities
  merchantCapabilities: (
    | 'supports3DS'
    | 'supportsCredit'
    | 'supportsDebit'
    | 'supportsEMV'
  )[]

  merchantIdentifier: string

  // https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/1916122-supportednetworks
  supportedNetworks: (
    | 'amex'
    | 'bancomat'
    | 'bancontact'
    | 'cartesBancaires'
    | 'chinaUnionPay'
    | 'dankort'
    | 'discover'
    | 'eftpos'
    | 'electron'
    | 'elo'
    | 'girocard'
    | 'interac'
    | 'jcb'
    | 'mada'
    | 'maestro'
    | 'masterCard'
    | 'mir'
    | 'privateLabel'
    | 'visa'
    | 'vPay'
  )[]

  supportedCountries?: string[]
}

export type OneyPaymentMethodConfig = {
  legal?: {
    creditNotice: boolean
  }
  libraryUrl?: string
  merchantGuid: string
}

export type PaypalPaymentMethodConfig = {
  paypalClientId: string
}

export type ProcessOutBasePaymentMethodConfig = {
  publicKey: string
}

export type ProcessOutCardPaymentMethodConfig =
  ProcessOutBasePaymentMethodConfig & {
    preferredNetworks?: PaymentNetwork[]
  }

export type ProcessOutPaypalPaymentMethodConfig =
  ProcessOutBasePaymentMethodConfig & PaypalPaymentMethodConfig

type GooglePaymentMethodType = 'CARD' | 'PAYPAL'
type GoogleCardNetworks =
  | 'AMEX'
  | 'DISCOVER'
  | 'ELECTRON'
  | 'ELO'
  | 'ELO_DEBIT'
  | 'INTERAC'
  | 'JCB'
  | 'MAESTRO'
  | 'MASTERCARD'
  | 'VISA'
type GoogleCardAuthMethod = 'PAN_ONLY' | 'CRYPTOGRAM_3DS'

export type GooglePaymentMethodConfig = {
  environment: 'TEST' | 'PRODUCTION'
  type: GooglePaymentMethodType
  parameters: {
    allowedAuthMethods: GoogleCardAuthMethod[]
    allowedCardNetworks: GoogleCardNetworks[]
  }
  tokenizationSpecification:
    | {
        type: 'PAYMENT_GATEWAY'
        parameters: Record<string, string>
      }
    | {
        type: 'DIRECT'
        parameters: {
          protocolVersion: string
          publicKey: string
        }
      }
  merchantInfo: {
    merchantId: string
    merchantName: string
  }
}

/**
 * Config object provided by the Payment Methods API
 */
export type PaymentMethodConfig =
  | AdyenCardPaymentMethodConfig
  | AdyenIdealPaymentMethodConfig
  | ApplePayPaymentMethodConfig
  | KlarnaPaymentMethodConfig
  | OneyPaymentMethodConfig
  | ProcessOutBasePaymentMethodConfig
  | ProcessOutCardPaymentMethodConfig
  | ProcessOutPaypalPaymentMethodConfig
  | SquarePaymentMethodConfig
  | GooglePaymentMethodConfig
  // Using Record<PropertyKey,never> generates type issues
  // eslint-disable-next-line @typescript-eslint/ban-types
  | {}

/**
 * Object representing a payment method
 *
 * Every payment method represent a unique combination of
 * (`bmCode`, `pspCode`, `countryCode`, `architecture`), and has a unique
 * `reference`, which is used as source of truth when
 */
export type PaymentMethod = {
  /**
   * Backmarket code of the payment method
   */
  bmCode: PaymentMethodCode

  /**
   * Payment provider used through the payment method
   */
  pspCode: PaymentServiceProvider

  /**
   * Country of the payment method
   */
  countryCode: `${MarketCountryCode}`

  /**
   * Currency of the payment method
   */
  currency: `${Currency}`

  /**
   * Payment provider specific name for payment method
   * @example 'facilypay_3x' for Oney 3x
   * @deprecated https://backmarket.atlassian.net/browse/PAYIN-4854
   */
  brandCode: string

  /**
   * Group of the payment method
   */
  group: PaymentGroup

  /**
   * List of brands to display, when advertising the payment method
   */
  networks: Array<PaymentNetwork>

  /**
   * Whether the payment method supports payment with promo codes, or not
   * @deprecated https://backmarket.atlassian.net/browse/PAYIN-4854
   */
  promoCodeEnabled: boolean

  /**
   * Whether the payment page is hosted on a third party website, or not
   * @deprecated https://backmarket.atlassian.net/browse/PAYIN-4854
   */
  hppEnabled: boolean

  /**
   * Whether the payment method automatically captures, or not
   * @deprecated https://backmarket.atlassian.net/browse/PAYIN-4854
   */
  autoCaptureEnabled: boolean

  /**
   * Fraud system used with this payment method
   */
  fraudCheckPartner: PaymentFraudPartner | ''

  /**
   * Whether the payment method is enabled
   */
  enabled: boolean

  /**
   * Whether the payment method requires merchant agreement, or not.
   * @deprecated https://backmarket.atlassian.net/browse/PAYIN-4854
   */
  needMerchantAgreement: boolean

  /**
   * List of errors which prevents the payment method to be available
   */
  errors: Array<PaymentError>

  /**
   * Unique identifier that should be used to proceed to the payment
   */
  reference: string

  /**
   * Type of integration used for this payment method
   * @deprecated https://backmarket.atlassian.net/browse/PAYIN-4854
   */
  integrationType: PaymentIntegrationType | null

  /**
   * Custom options for a payment method. These options vary from one payment
   * method to another
   */
  config: PaymentMethodConfig

  /**
   * BackMarket payment flow architecture
   * @deprecated https://backmarket.atlassian.net/browse/PAYIN-4854
   */
  architecture: PaymentArchitecture
}

export type LoanSimulationInstallment = {
  number: number
  collectionDate: string
  amount: number
}

export type LoanSimulation = {
  installments: LoanSimulationInstallment[]
  amount: number
  downAmount: number
  cost: number
  totalAmount: number
}

export type MonthlyPaymentPlan = {
  annualPercentageRate: number
  monthlyPrice: Price
  numberOfMonths: number
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GetPaymentMethods {
  export type Response = DjangoPaginatedResults<PaymentMethod>
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GetLoanSimulation {
  export type Response = LoanSimulation
}

export type PaymentCreateFraudData = {
  /**
   * Signifyd device fingerprint
   */
  signifyd_fingerprint?: string

  /**
   * Ravelin device fingerprint
   */
  deviceId?: string
}

export type PaymentCreateBody = PaymentCreateFraudData & {
  payment_method: {
    config?: Record<string, unknown>
    reference?: string
  }
  requested_price: Price
}

export type CreatePaymentResponse = {
  paymentId: string
  gatewayReference?: string
  gatewayTokenReference?: string
  nextAction?: CreatePaymentNextAction
}

export type CreatePaymentNextAction =
  | CreatePaymentNextActionRedirect
  | CreatePaymentNextActionVoucher

export type CreatePaymentNextActionRedirect = {
  type: 'redirect'
  url: string
  httpMethod: 'GET' | 'POST'
  data?: Record<string, string>
}

export type CreatePaymentNextActionVoucher = {
  type: 'voucher'
  expirationDate: string
  code: string
  instructionsUrl: string
  phoneNumber: string
  reference: string
}

export type PostConfirmCardResponse = {
  nextAction?: CreatePaymentNextAction
}

export type PostConfirmHppResponse = {
  nextAction?: CreatePaymentNextAction
}

export type NextAction = 'RETRY' | 'SHOW_SUCCESS' | 'SHOW_ERROR'

export type GetNextActionResponse = {
  action: NextAction
  type?: string
  data?: {
    title: string
    detail: string
  }
}

export type PostPaymentApplePaySession = {
  session: Record<string, unknown>
}

export type GetEncryptionTokenResponse = {
  adyenEncryptionToken: string
  generationTime: string
}
